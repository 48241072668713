<template>
  <div v-if="$route.params.tab == 'storages'">
    <page-component
      pageTitle="Storages"
      :headers="storageHeaders"
      :items="storages"
      :loadingData="storagesLoaded"
      :buttons="[
        { text: '+ Add New Storage', to: { params: { form: 'AddEditForm' } } },
      ]"
    >
      <template v-slot:[`item.restaurantIds`]="props">
        {{ restaurantName(props.item.restaurantIds) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="edit" @click="editStorage(item)" />
        <nxg-action-btn type="delete" item-type="STORAGE" :item="item" />
      </template>
    </page-component>
    <add-edit-form
      :visible="showForm"
      :storageToEdit="storageToEdit"
      :storageEditMode="storageEditMode"
      @close="
        showForm = false
        storageEditMode = false
      "
    />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import { mapState, mapGetters, mapActions } from "vuex"
import PageComponent from "../../shared/PageComponent.vue"

export default {
  name: "storages",
  components: {
    AddEditForm,
    PageComponent,
  },
  data() {
    return {
      showForm: false,
      storageToEdit: {},
      storageEditMode: false,
      searchStorages: "",
      storageHeaders: [
        { text: "Name", value: "name", class: "header-style" },
        { text: "Location", value: "location", class: "header-style" },
        { text: "Restaurant", value: "restaurantIds", class: "header-style" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "header-style",
        },
      ],
      selectedStorages: [],
      storagesLoaded: false,
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName"]),
    ...mapState(["storages"]),
  },
  mounted() {
    this.fetchInventoryData()
  },
  methods: {
    ...mapActions(["fetchInventoryData"]),
    restaurantName(restaurantIds) {
      var nameArray = []
      restaurantIds?.forEach(id => {
        nameArray.push(this.getRestaurantName(id))
      })
      return nameArray.join(", ")
    },
    async editStorage(storage) {
      await this.$store.commit("setItemToEdit", storage)
      this.$router.push({ params: { form: "AddEditForm", id: storage.id } })
    },
  },
}
</script>

<style></style>
