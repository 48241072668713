import { mapGetters, mapState } from "vuex"

export default {
  computed: {
    ...mapState(["settings", "settingsByVisibility"]),
    ...mapGetters(["isNextGenUser"]),
    fieldsBySection() {
      const otherFields = this.otherFields
      // combine default fields with other fields, and group them by section
      const allFields = {
        ...this.defaultFields,
        other: [],
      }
      otherFields.forEach(field => {
        if (field.path?.length === 1) {
          allFields.other.push(field)
        } else {
          const section = field.path[0]
          if (allFields[section]) {
            allFields[section].push(field)
          } else {
            allFields[section] = [field]
          }
        }
      })
      // filter any items that have the hide property set to true
      return Object.entries(allFields).reduce((acc, [key, value]) => {
        acc[key] = value.filter(field => !field.hide)
        return acc
      }, {})
    },
    defaultFields() {
      return {
        // path dictates object structure in firebase, documents dictates where the field is stored
        hours: [
          {
            title: "",
            type: "hours",
            path: ["hours"],
            documents: ["Public"],
          },
        ],
        fees: [
          {
            title: "Commission",
            type: "percent",
            path: ["organizationFee"],
            documents: ["Organization"],
          },
          {
            title: "GST on Commission",
            type: "percent",
            path: ["organizationFeeGST"],
            documents: ["Organization"],
          },
          {
            title: "PST on Commission",
            type: "percent",
            path: ["organizationFeePST"],
            documents: ["Organization"],
          },
          {
            title: "Other Tax on Commission",
            type: "percent",
            path: ["organizationFeeTax"],
            documents: ["Organization"],
          },
          {
            title: "Organization Tip Split",
            type: "percent",
            path: ["organizationTip"],
            documents: ["Organization"],
          },
          {
            title: "Service Fee",
            type: "percent",
            path: ["serviceFee"],
            documents: ["Public"],
          },
          {
            title: "Service Fee Tax",
            type: "percent",
            path: ["serviceFeeTax"],
            documents: ["Public"],
          },
          {
            title: "Bag Fee Tax",
            type: "percent",
            path: ["bagFee", "taxPercent"],
            documents: ["Public"],
          },
        ],
        commissions: [
          {
            title: "",
            type: "percent",
            path: ["restaurants"],
            documents: ["Organization"],
          },
        ],
        payments: [
          {
            title: "Payment Provider",
            path: ["paymentProvider"],
            documents: ["Public"],
          },
          // null / non-existing 'environment' field in settings will default to Off or Production
          {
            title: "Test Mode",
            type: "boolean",
            path: ["environment"],
            documents: ["Public"],
          },
          {
            title: "Tax Rounding Method",
            path: ["taxRoundingMethod"],
            documents: ["Public"],
          },
          {
            title: "Stripe Publishable Key (Live)",
            path: ["stripe", "publishableKey", "production"],
            documents: ["Organization"],
            hide: this.settings.paymentProvider !== "stripe",
          },
          {
            title: "Stripe Publishable Key (Sandbox)",
            path: ["stripe", "publishableKey", "sandbox"],
            documents: ["Organization"],
            hide: this.settings.paymentProvider !== "stripe",
          },
          {
            title: "Stripe Publishable Key",
            path: ["stripePublishableKey"],
            documents: ["Public"],
            hide:
              this.settings.paymentProvider !== "stripe" ||
              this.settings.stripe !== undefined,
          },
          {
            title: "Stripe Secret Key",
            path: ["stripeSecretKey"],
            documents: ["Organization"],
            hide: this.settings.paymentProvider !== "stripe",
          },
          {
            title: "Stripe Location Id",
            path: ["stripeLocationId"],
            documents: ["Organization"],
            hide: this.settings.paymentProvider !== "stripe",
          },
        ],
        colors: [
          {
            title: "Organization Color",
            type: "color",
            path: ["organizationColor"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Organization Text Color",
            type: "color",
            path: ["organizationTextColor"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Kiosk Organization Color",
            type: "color",
            path: ["kioskOrganizationColor"],
            documents: ["Public"],
          },
        ],
        display: [
          {
            title: "Organization Logo",
            type: "image",
            path: ["organizationLogo"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Organization Logo Dark",
            type: "image",
            path: ["organizationLogoDark"],
            documents: ["Public"],
          },
          {
            title: "Organization Header Image",
            type: "image",
            path: ["organizationHeader"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Kiosk Logo",
            type: "image",
            path: ["kioskOrganizationLogo"],
            documents: ["Public"],
          },
          {
            title: "Logo Has Transparent Background",
            type: "boolean",
            path: ["transparentLogo"],
            documents: ["Public"],
          },
        ],
        about: [
          {
            title: "Organization Name",
            path: ["organizationName"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Organization Phone Number",
            type: "phone",
            path: ["organizationPhoneNumber"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Organization Address",
            path: ["organizationAddress"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Web URL",
            path: ["webURL"],
            documents: ["Public"],
          },
        ],
        appAndCheckout: [
          {
            title: "Home Layout",
            path: ["appDisplayOptions", "homeLayout"],
            documents: ["Public"],
          },
          {
            title: "Dine In Enabled",
            type: "boolean",
            path: ["dineInEnabled"],
            documents: ["Public"],
          },
          {
            title: "Use Table Numbers",
            type: "boolean",
            path: ["usesTableNumbers"],
            documents: ["Public"],
            hide: this.settings.dineInEnabled !== true,
          },
          {
            title: "Single Brand Enabled",
            type: "boolean",
            path: ["singleBrandEnabled"],
            documents: ["Public"],
          },
          {
            title: "Pickup Points Enabled",
            type: "boolean",
            path: ["pickupPointsEnabled"],
            documents: ["Public"],
          },
          {
            title: "Pos Loyalty Enabled",
            type: "boolean",
            path: ["posLoyaltyEnabled"],
            documents: ["Public"],
          },
          {
            title: "Google Play Store URL",
            path: ["googlePlayStoreURL"],
            documents: ["Public"],
          },
          {
            title: "App Store URL",
            path: ["iosStoreURL"],
            documents: ["Public"],
          },
          {
            title: "App Store App ID",
            path: ["iosAppId"],
            documents: ["Public"],
          },
        ],
        delivery: [
          {
            title: "Free Delivery Multiplier",
            type: "number",
            path: ["delivery", "freeDeliveryMultiplier"],
            documents: ["Public"],
          },
          {
            title: "Minimum Order For Free Delivery",
            type: "dollar",
            path: ["delivery", "minimumOrder"],
            documents: ["Public"],
          },
          {
            title: "Pickup Instructions",
            path: ["delivery", "pickupInstructions"],
            documents: ["Public"],
          },
          {
            title: "Action If Undeliverable",
            path: ["delivery", "actionIfUndeliverable"],
            documents: ["Public"],
          },
          {
            title: "Organization Coverage",
            path: ["delivery", "organizationCoverage", "amount"],
            type: "dollar",
            documents: ["Public"],
          },
        ],
        referral: [
          {
            title: "Referral Enabled",
            path: ["referralEnabled"],
            type: "boolean",
            documents: ["Public"],
          },
          {
            title: "Referral Amount",
            path: ["referralAmount", "amount"],
            type: "dollar",
            documents: ["Public"],
          },
          {
            title: "Referral Amount Type",
            path: ["referralAmount", "type"],
            documents: ["Public"],
          },
          {
            title: "Referral Organization Coverage",
            type: "percent",
            path: ["referralAmount", "organizationCoveragePercent"],
            documents: ["Public"],
          },
        ],
        storeDisplay: [
          {
            title: "Promo Images Enabled",
            type: "boolean",
            path: ["promoImagesEnabled"],
            documents: ["Organization"],
          },
          {
            title: "Order Status Enabled",
            type: "boolean",
            path: ["orderStatus", "enabled"],
            documents: ["Organization"],
          },
          {
            title: "Kiosk Only",
            type: "boolean",
            path: ["orderStatus", "kioskOnly"],
            documents: ["Organization"],
          },
        ],
      }
    },
    otherFields() {
      //************************/
      // HELPER FUNCTIONS
      //************************/

      // flattens the settings object to a single level object
      const flattenObject = (obj, prefix = "") => {
        return Object.keys(obj).reduce((acc, key) => {
          const newKey = prefix ? `${prefix}.${key}` : key
          if (
            typeof obj[key] === "object" &&
            obj[key] !== null &&
            !Array.isArray(obj[key])
          ) {
            Object.assign(acc, flattenObject(obj[key], newKey))
          } else {
            acc[newKey] = obj[key]
          }
          return acc
        }, {})
      }
      // find which visibility a field belongs to by checking the settingsByVisibility object
      const visibility = fieldPath => {
        for (const [key, value] of Object.entries(this.settingsByVisibility)) {
          if (value.hasOwnProperty(fieldPath.split(".")[0])) {
            return key
          }
        }
        return "Unknown"
      }
      // determine type from settings (one of: text, color, image, boolean, percent, dollar, number)
      const getType = value => {
        if (typeof value === "string") {
          if (value.startsWith("#")) {
            return "color"
          } else if (/^https?:\/\/.+\.(png|jpg|jpeg|gif|svg|webp)$/.test(value)) {
            return "image"
          } else {
            return "text"
          }
        } else if (typeof value === "boolean") {
          return "boolean"
        } else if (typeof value === "number") {
          if (value % 1 === 0) {
            return "number"
          } else {
            return "dollar"
          }
        }
      }

      //************************/
      // MAIN FUNCTION
      //************************/

      const settingsFiltered = Object.entries(this.settings).filter(([key]) => {
        return (
          key !== "restaurants" &&
          !key.startsWith("onboarding") &&
          !key.startsWith("hours") &&
          !key.startsWith("loyalty") &&
          visibility(key) !== "Private"
        )
      })
      const settingsFilteredObject = settingsFiltered.reduce((acc, [key, value]) => {
        acc[key] = value
        return acc
      }, {})
      const settingsFlattened = Object.entries(flattenObject(settingsFilteredObject))
      const existingFields = Object.values(this.defaultFields).reduce(
        (acc, section) => {
          return acc.concat(
            section
              ?.map(field => field.path.join("."))
              .filter(fieldPath => {
                return settingsFlattened.find(([key]) => key === fieldPath)
              })
          )
        },
        []
      )
      const otherFields = settingsFlattened
        .filter(([key]) => {
          // for example if the key is "hours.monday.open" it should return true if existingFields does not include "hours", "hours.monday", or "hours.monday.open"
          return !existingFields.some(existingField => {
            return key.startsWith(existingField) || key === existingField
          })
        })
        .map(([key, value]) => {
          return {
            title: this.keyToWord(key),
            type: getType(value),
            path: key.split("."),
            documents: [visibility(key)],
          }
        })
      return otherFields
    },
    parsedFields() {
      // fetches firebase settings values based on the path field within each object, if none found, it is not included in the array
      const parsedFieldsBySection = {}
      for (const section in this.fieldsBySection) {
        parsedFieldsBySection[section] = this.fieldsBySection[section].reduce(
          (result, row) => {
            const fieldValue = this.getFieldValue(row, this.settings, row.path)
            result.push({
              ...row,
              value: fieldValue,
            })
            return result
          },
          []
        )
      }

      return parsedFieldsBySection
    },
  },
  methods: {
    getSettingsSection(section) {
      const sectionFormatted = this.parsedFields[section]
      const sectionName = this.keyToWord(section)
      sectionFormatted.forEach(field => {
        // determine if the field title starts with the section name, if so, remove it
        if (field.title.startsWith(sectionName)) {
          field.title = field.title.slice(sectionName.length + 1)
          if (field.title.startsWith("> ")) {
            field.title = field.title.slice(2)
          }
        }
      })
      return sectionFormatted
    },
    getFieldValue(row, value, path) {
      // fetches firebase field value recursively based on the path parameter, returns null if value not found
      if (value == null) {
        return null
      }

      if (path.length === 0) {
        return value
      } else {
        let nestedValue = value[path[0]]
        return this.getFieldValue(row, nestedValue, path.slice(1))
      }
    },
    keyToWord(key) {
      let titleParts = key.split(".")
      titleParts = titleParts.map(part => {
        // change from camelCase to title case, including adding spaces between words (skip sequences of uppercase letters)
        // e.g. webURL -> Web URL, webURLs -> Web URLs, webURLsAndMore -> Web URLs And More
        const word = part.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      return titleParts.length == 1
        ? titleParts[0]
        : titleParts[0] + " > " + titleParts.slice(1).join(" ")
    },
  },
}
