<template>
  <div>
    <page-component
      pageTitle="Payments"
      :headers="headers"
      :items="payments"
      :loadingData="false"
    >
      <template v-slot:[`item.payment`]="{ item }">
        <div class="d-flex align-center">
          <img
            v-if="item.payment === 'Square'"
            :src="require('@/assets/logos/square.webp')"
            class="mx-n2"
            width="80"
          />
          <img
            v-else
            :src="require('@/assets/logos/stripe.webp')"
            class="mx-n2"
            width="80"
          />
          <span class="text--disabled ml-4" style="text-transform: capitalize" small>
            {{ item.payment }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-switch
          v-model="item.active"
          color="success"
          :label="item.active ? 'enabled' : 'disabled'"
          :disabled="hasActivePayment && !item.active"
          @change="$emit('update', { status: item.active })"
        ></v-switch>
        <!-- hint="Only one provider can be enabled at a time"
          :persistent-hint="hasActivePayment && !item.active" -->
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn
          type="edit"
          @click="editPayment(item.payment.toLowerCase())"
        />
      </template>
    </page-component>
    <add-edit-form />
  </div>
</template>

<script>
import PageComponent from "../../shared/PageComponent.vue"
import AddEditForm from "./AddEditForm.vue"

export default {
  name: "channels",
  components: { PageComponent, AddEditForm },
  data() {
    return {
      headers: [
        { text: "Payment method", value: "payment" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      payments: [
        {
          payment: "Stripe",
          type: "multi-vendor",
          active: false,
        },
        {
          payment: "Square",
          type: "single-vendor",
          active: false,
        },
      ],
    }
  },
  computed: {
    hasActivePayment() {
      const isActive = this.payments.some(payment => payment.active)
      return isActive
    },
  },
  methods: {
    async editPayment(payment) {
      this.$router.push({ params: { form: "AddEditForm", id: payment } })
    },
  },
}
</script>
