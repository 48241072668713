<template>
  <div v-if="$route.params.tab == 'units'">
    <h2 class="my-4 text-uppercase">Units</h2>
    <div class="mt-10">
      <v-row justify="start" class="my-2">
        <v-col cols="auto">
          <v-btn color="primary" :to="{ params: { form: 'AddEditForm' } }">
            + Add New Unit
          </v-btn>
        </v-col>
      </v-row>
      <v-flex d-flex class="mt-4">
        <v-layout wrap>
          <v-flex v-for="(unit, i) in units" :key="i" md3>
            <v-card class="mr-4 my-4" outlined elevation="15">
              <h3 class="ma-3">
                1 {{ unit.fullName }}
                <span v-if="unit.name !== unit.fullName">({{ unit.name }})</span>
                = {{ unit.valInBase }} {{ unit.base }}
                <span v-if="unit.valInBase > 1">s</span>
              </h3>
              <v-card-actions class="mt-auto">
                <v-spacer />
                <v-icon @click="editUnit(unit)">mdi-pencil</v-icon>
                <v-icon
                  @click="
                    itemToDelete = unit
                    showDeleteDialog = true
                  "
                >
                  mdi-delete
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </div>
    <add-edit-form
      v-if="$route.params.form == 'AddEditForm'"
      @close="$router.push({ params: { form: null, id: null }, query: {} })"
    />
    <delete-dialog
      :show="showDeleteDialog"
      :itemToDelete="itemToDelete"
      itemType="UNIT"
      @close="showDeleteDialog = !showDeleteDialog"
    />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import { mapState, mapActions } from "vuex"
import DeleteDialog from "../../shared/dialogs/DeleteDialog.vue"

export default {
  name: "units",
  components: { AddEditForm, DeleteDialog },
  data() {
    return {
      itemToDelete: {},
      showDeleteDialog: false,
    }
  },
  computed: {
    ...mapState(["units"]),
  },
  mounted() {
    this.fetchInventoryData()
  },
  methods: {
    ...mapActions(["fetchInventoryData", "deleteUnit"]),
    editUnit(unit) {
      this.$router.push({
        params: { form: "AddEditForm", id: unit.id },
      })
    },
  },
}
</script>

<style></style>
