<template>
  <form-dialog
    :visible="$route.params.form == 'Options'"
    title="Options"
    :editMode="true"
    :isCloseDiabled="closeDisabled"
    :awaitingResponse="awaitingResponse"
    :error="mainErrorMessage"
    :submitDisabled="disablePoints"
    @close="clearFields()"
    @submit="submitHandle"
  >
    <v-row justify="start" class="mt-2">
      <v-col cols="6">
        <v-text-field
          v-model.number="loyaltyPointsPerDollar"
          label="Points Per Dollar"
          outlined
          :disabled="disablePoints"
          :error-messages="getFieldError($v.loyaltyPointsPerDollar)"
        />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState } from "vuex"
import { numeric } from "vuelidate/lib/validators"
import getFieldError from "@/mixins/getFieldError"

export default {
  name: "options-form",
  components: {
    FormDialog,
  },
  mixins: [getFieldError],
  data() {
    return {
      loyaltyPointsPerDollar: null,
      mainErrorMessage: "",
      closeDisabled: false,
      awaitingResponse: false,
    }
  },
  validations() {
    return {
      pointsPerDollar: { numeric },
    }
  },
  computed: {
    ...mapState(["settings"]),
    disablePoints() {
      return this.settings?.loyaltyPointsPerDollar != null
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "Options") {
          this.prepEdit()
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async submitHandle() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.closeDisabled = this.awaitingResponse = true

      await this.$store
        .dispatch("updateLoyaltyOptions", {
          loyaltyPointsPerDollar: this.loyaltyPointsPerDollar,
        })
        .then(() => {
          this.clearFields()
        })
        .catch(err => {
          this.mainErrorMessage = String(err)
        })
      this.awaitingResponse = this.closeDisabled = false
    },
    clearFields() {
      this.prepEdit()
      this.mainErrorMessage = ""
      this.$router.push({ params: { form: null, id: null } })
    },
    prepEdit() {
      this.loyaltyPointsPerDollar = this.settings?.loyaltyPointsPerDollar || null
    },
  },
}
</script>
