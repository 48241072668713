export default {
  methods: {
    formatPrice(price, includeDollarSign = false) {
      if (isNaN(price) || !price) price = 0
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      })

      if (includeDollarSign) return formatter.format(price / 100)
      else return formatter.format(price / 100).replace("$", "")
    },
    // reverse of formatPrice
    getNumberFromPrice(price) {
      price = price.toString()
      return parseFloat(price.replace(/[^0-9.-]+/g, "")) * 100
    },
  },
}
