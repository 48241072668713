<template>
  <div v-if="$route.params.tab == 'promoImages'">
    <page-component
      listType="cards"
      pageTitle="Promo Images"
      :headers="headers"
      :items="promoImages"
      :buttons="[
        { text: '+ Add New Promo Image', to: { params: { form: 'AddEditForm' } } },
      ]"
      :loadingData="loading"
      enableCardView
    >
      <template v-slot:card="{ item }">
        <div v-if="item.isVideo" style="position: relative">
          <video
            :ref="`video${item.id}`"
            :style="`width: 100%; min-height: 226px; object-fit: ${
              item.containImage ? 'contain' : 'cover'
            };`"
            class="mb-n2"
            playsinline
            muted
            loop
          >
            <source :src="item.imageUrl" type="video/mp4" />
          </video>
          <!-- play button on video, centered -->
          <v-btn
            text
            absolute
            top
            rounded
            class="mt-2"
            style="
              z-index: 1;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 60px;
              width: 60px;
              border-radius: 100%;
            "
            dark
            @click="
              $refs[`video${item.id}`].paused
                ? $refs[`video${item.id}`].play()
                : $refs[`video${item.id}`].pause()
            "
          >
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </div>
        <v-img
          v-else
          :src="item.imageUrl"
          :style="`background-color: ${item.backgroundColor};`"
          height="226"
          :contain="item.containImage"
        ></v-img>
        <v-card-title>
          {{ item.title || "Untitled" }}
        </v-card-title>
        <v-card-subtitle>
          {{ item.subtitle || " --- " }}
        </v-card-subtitle>
        <v-card-text class="mb-0">
          <v-row>
            <v-col cols="6">
              Start:
              {{ parseInt(item.startSec, 10).toLocaleString("en-US") || 0 }} ms
            </v-col>
            <v-col cols="6" class="text-right">
              Duration:
              {{ parseInt(item.duration, 10).toLocaleString("en-US") || 0 }} ms
            </v-col>
          </v-row>
        </v-card-text>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="edit" @click="editPromotion(item)" />
        <nxg-action-btn type="delete" item-type="PROMO_IMAGE" :item="item" />
      </template>
    </page-component>
    <add-edit-form />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import { mapState, mapActions, mapGetters } from "vuex"
import PageComponent from "../../shared/PageComponent.vue"

export default {
  name: "promo-images",
  components: {
    AddEditForm,
    PageComponent,
  },
  data() {
    return {
      headers: [
        { text: "Title", value: "title" },
        { text: "Subtitle", value: "subtitle" },
        { text: "Image URL", value: "imageUrl" },
        { text: "Start", value: "startSec" },
        { text: "Duration", value: "duration" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      loading: true,
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName"]),
    ...mapState(["promoImages"]),
  },
  async mounted() {
    if (this.promoImages?.length) {
      this.loading = false
    } else {
      await this.fetchPromoImages()
      this.loading = false
    }
  },
  methods: {
    ...mapActions(["fetchPromoImages"]),
    restaurantName(restaurantIds) {
      var nameArray = []
      restaurantIds?.forEach(id => {
        nameArray.push(this.getRestaurantName(id))
      })
      return nameArray.join(", ")
    },
    async editPromotion(promotion) {
      this.$router.push({ params: { form: "AddEditForm", id: promotion.id } })
    },
  },
}
</script>

<style></style>
