<template>
  <v-app>
    <div>
      <v-card id="inventoryCategory" elevation="1">
        <v-card-title>Inventory Categories</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="1">
              <v-btn @click="newCategory = !newCategory">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col v-show="newCategory" cols="3">
              <v-text-field
                v-model="saveCategory"
                label="Category Name"
                outlined
                dense
              />
            </v-col>
            <v-col v-show="newCategory" cols="3">
              <v-btn
                color="primary"
                @click="
                  updateCategories(saveCategory)
                  saveCategory = null
                  newCategory = !newCategory
                "
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="(category, i) in categories" :key="i" cols="auto">
              {{ category }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card id="subcategory" class="mt-5" elevation="1">
        <v-card-title>Subcategories</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="1">
              <v-btn @click="newSubcategory = !newSubcategory">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col v-show="newSubcategory" cols="3">
              <v-text-field
                v-model="saveSubcategory"
                label="Subcategory"
                outlined
                dense
              />
            </v-col>
            <v-col v-show="newSubcategory" cols="3">
              <v-btn
                color="primary"
                @click="
                  updateSubcategories(saveSubcategory)
                  saveSubcategory = null
                  newSubcategory = !newSubcategory
                "
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="(subcategory, i) in subcategories" :key="i" cols="auto">
              {{ subcategory }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-5" elevation="1">
        <v-card-title>Suppliers</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="1">
              <v-btn @click="newSupplier = !newSupplier">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col v-show="newSupplier" cols="3">
              <v-text-field
                v-model="supplierName"
                label="Name"
                single-line
                outlined
                dense
              />
            </v-col>
            <v-col v-show="newSupplier" cols="3">
              <v-text-field
                v-model="supplierNumber"
                label="Phone Number"
                single-line
                outlined
                dense
              />
            </v-col>
            <v-col v-show="newSupplier" cols="4">
              <v-text-field
                v-model="supplierEmail"
                label="Email"
                single-line
                outlined
                dense
              />
            </v-col>
            <v-col v-show="newSupplier" cols="1">
              <v-btn
                color="primary"
                @click="
                  updateSuppliers({
                    email: supplierEmail,
                    name: supplierName,
                    phoneNumber: supplierNumber,
                    id: uuidv4(),
                  })
                  supplierName = supplierNumber = supplierEmail = null
                  newSupplier = !newSupplier
                "
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="(supplier, i) in suppliers" :key="i" cols="auto">
              {{ supplier.name }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex"

export default {
  data() {
    return {
      saveCategory: null,
      saveSubcategory: null,
      newCategory: false,
      newSubcategory: false,
      newSupplier: false,
      supplierName: null,
      supplierNumber: null,
      supplierEmail: null,
    }
  },

  computed: mapState(["restaurants", "categories", "subcategories", "suppliers"]),
  methods: {
    ...mapActions(["updateCategories", "updateSubcategories", "updateSuppliers"]),
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      )
    },
  },
}
</script>
