<template>
  <v-list-item class="d-flex flex-no-wrap align-center">
    <v-row align="center" class="w-100">
      <v-col :cols="fullWidth ? 3 : 5" :class="`pa-0 pl-${indent * 4}`">
        <v-list-item-title class="text-body-2">
          {{ title }}
        </v-list-item-title>
      </v-col>
      <v-col :cols="fullWidth ? 8 : 6" class="pa-1">
        <div v-if="type === 'image'">
          <v-img
            v-if="value && value.length"
            :src="value"
            width="100px"
            height="100px"
            class="my-3"
            contain
          ></v-img>
          <v-icon v-else class="mx-7" large>mdi-image-off-outline</v-icon>
        </div>
        <v-col v-else cols="12" class="py-0">
          <v-list-item-subtitle
            v-if="type !== 'color'"
            class="subtitle-2 lighttext--text"
          >
            <span
              v-if="type === 'boolean'"
              :style="`color: ${
                value === true ? 'green' : value === false ? 'red' : 'inherit'
              }`"
            >
              {{ value === true ? "Yes" : value === false ? "No" : value }}
            </span>
            <span v-else-if="title.includes('Secret') || title.includes('Password')">
              {{ "*".repeat(value.length) }}
            </span>
            <span v-else>{{ value }}</span>
          </v-list-item-subtitle>
          <v-btn
            v-else
            x-small
            elevation="0"
            :color="value"
            style="border: solid 1px #ddd"
            :style="{
              backgroundColor: `${value} !important`, // keeps background color when disabled
            }"
            disabled
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </v-btn>
        </v-col>
      </v-col>
      <v-col v-if="isNextGenUser" cols="1" class="text-right">
        {{ visibility.substring(0, 3) }}
      </v-col>
    </v-row>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "settings-row",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Array],
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    visibility: {
      type: String,
      default: "",
    },
    indent: {
      type: Number,
      default: 0,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["isNextGenUser"]),
  },
}
</script>
