<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="Unit"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      @close="clearFields"
      @submit="submitHandle"
    >
      <h3 class="my-5">Unit Information</h3>
      <v-flex d-flex class="mt-2">
        <h2 class="mt-4 mr-3">1&nbsp;x</h2>
        <v-text-field v-model="newUnit.fullName" label="Unit Full Name" outlined />
        <h2 class="mt-4 mx-3">(</h2>
        <v-text-field v-model="newUnit.name" label="Unit Short Name" outlined />
        <h2 class="mt-4 mx-3">)&nbsp;&nbsp;=</h2>
        <v-text-field
          v-model="newUnit.valInBase"
          label="Conversion rate"
          :hint="newUnit.base + 's in a ' + newUnit.name"
          :persistent-hint="false"
          outlined
        />
        <h2 class="mt-4 mx-3">x</h2>
        <v-select
          v-model="newUnit.base"
          :items="bases"
          outlined
          label="Unit
            Base"
          hint="Item measured in g, ml, or /item"
          :persistent-hint="true"
        />
      </v-flex>
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState, mapActions } from "vuex"

export default {
  name: "add-edit-form",
  components: { FormDialog },
  data() {
    return {
      newUnit: {
        name: "",
        fullName: "",
        base: "",
        valInBase: "",
      },
      bases: ["gram", "milliliter", "unit"],
      closeDisabled: false,
      awaitingResponse: false,
      mainErrorMessage: "",
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "units"]),
    editMode() {
      return this.itemToEdit != null
    },
    itemToEdit() {
      return this.$route.params.id
        ? this.units.find(item => item.id == this.$route.params.id)
        : null
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["updateUnits"]),
    async submitHandle() {
      this.closeDisabled = true
      this.awaitingResponse = true
      await this.updateUnits(this.newUnit)
        .then(() => {
          this.$emit("close")
          this.clearFields()
        })
        .catch(error => {
          this.mainErrorMessage = "Error saving unit: " + error.message
        })
        .finally(() => {
          this.awaitingResponse = false
          this.closeDisabled = false
        })
    },
    async prepEdit() {
      this.newUnit = this.itemToEdit
    },
    clearFields() {
      this.newUnit = {
        name: "",
        fullName: "",
        base: "",
        valInBase: "",
      }
      this.closeDisabled = this.awaitingResponse = false
      this.mainErrorMessage = ""
      if (this.$route.params.form != null) {
        this.$router.push({ params: { form: null, id: null } })
      }
      this.$emit("close")
    },
  },
}
</script>
