var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.params.tab == 'promoImages')?_c('div',[_c('page-component',{attrs:{"listType":"cards","pageTitle":"Promo Images","headers":_vm.headers,"items":_vm.promoImages,"buttons":[
      { text: '+ Add New Promo Image', to: { params: { form: 'AddEditForm' } } },
    ],"loadingData":_vm.loading,"enableCardView":""},scopedSlots:_vm._u([{key:"card",fn:function({ item }){return [(item.isVideo)?_c('div',{staticStyle:{"position":"relative"}},[_c('video',{ref:`video${item.id}`,staticClass:"mb-n2",style:(`width: 100%; min-height: 226px; object-fit: ${
            item.containImage ? 'contain' : 'cover'
          };`),attrs:{"playsinline":"","muted":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":item.imageUrl,"type":"video/mp4"}})]),_c('v-btn',{staticClass:"mt-2",staticStyle:{"z-index":"1","position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","height":"60px","width":"60px","border-radius":"100%"},attrs:{"text":"","absolute":"","top":"","rounded":"","dark":""},on:{"click":function($event){_vm.$refs[`video${item.id}`].paused
              ? _vm.$refs[`video${item.id}`].play()
              : _vm.$refs[`video${item.id}`].pause()}}},[_c('v-icon',[_vm._v("mdi-play")])],1)],1):_c('v-img',{style:(`background-color: ${item.backgroundColor};`),attrs:{"src":item.imageUrl,"height":"226","contain":item.containImage}}),_c('v-card-title',[_vm._v(" "+_vm._s(item.title || "Untitled")+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(item.subtitle || " --- ")+" ")]),_c('v-card-text',{staticClass:"mb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Start: "+_vm._s(parseInt(item.startSec, 10).toLocaleString("en-US") || 0)+" ms ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_vm._v(" Duration: "+_vm._s(parseInt(item.duration, 10).toLocaleString("en-US") || 0)+" ms ")])],1)],1)]}},{key:`item.actions`,fn:function({ item }){return [_c('nxg-action-btn',{attrs:{"type":"edit"},on:{"click":function($event){return _vm.editPromotion(item)}}}),_c('nxg-action-btn',{attrs:{"type":"delete","item-type":"PROMO_IMAGE","item":item}})]}}],null,true)}),_c('add-edit-form')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }