<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="User"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      @close="$emit('close')"
      @submit="submitHandle()"
    >
      <v-row justify="start" class="mt-2">
        <v-col cols="6">
          <v-text-field v-model="newUser.name" label="Name" outlined />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="newUser.role"
            :items="roleOptions"
            label="Role"
            outlined
          />
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6">
          <v-text-field
            v-model="newUser.email"
            autocomplete="off"
            label="Email"
            outlined
            :disabled="editMode"
          />
        </v-col>
        <v-col cols="6">
          <p v-if="editMode" class="mt-2">
            You cannot change the email associated with a user. To change the
            password, please send a reset password email to this user.
          </p>
          <v-text-field
            v-if="!editMode"
            v-model="newUser.password"
            autocomplete="off"
            label="Password"
            type="password"
            outlined
          />
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6">
          <v-phone-input
            v-model="newUser.phoneNumber"
            :label="`Phone ${newUser.role === 'Rider' ? '' : '(Optional)'}`"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-if="newUser.role != 'Admin'"
            v-model="newUser.restaurantIds"
            :items="restaurants"
            item-text="name"
            item-value="id"
            multiple
            outlined
            label="Select Restaurants"
          />
        </v-col>
      </v-row>
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState } from "vuex"
import updateUsers from "@/mixins/updateUsers"

export default {
  name: "add-edit-form",
  components: {
    FormDialog,
  },
  mixins: [updateUsers],
  data() {
    return {
      newUser: {
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        restaurantIds: [],
        role: "",
      },
      closeDisabled: false,
      awaitingResponse: false,
      mainErrorMessage: "",
    }
  },
  computed: {
    ...mapState(["currentUserRole", "restaurants", "itemToEdit", "settings"]),
    editMode() {
      return this.itemToEdit != null
    },
    roleOptions() {
      let roles = []
      if (this.currentUserRole === "Admin") {
        roles = ["Admin", "Manager", "Employee"]
        if (this.settings?.ownDeliveryEnabled) {
          roles.push("Rider")
        }
      } else if (this.currentUserRole === "Manager") {
        roles = ["Manager", "Employee"]
      } else {
        roles = []
      }
      return roles
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true

      if (!this.newUser.role) {
        this.mainErrorMessage = "Please select a role for this user."
        this.closeDisabled = this.awaitingResponse = false
        return
      }

      if (this.newUser.role !== "Admin" && this.newUser.restaurantIds.length === 0) {
        this.mainErrorMessage =
          "Please select at least one restaurant to associate this user with."
        this.closeDisabled = this.awaitingResponse = false
        return
      }

      if (this.newUser.role === "Rider" && !this.newUser.phoneNumber) {
        this.mainErrorMessage = "Please enter a valid phone number for this rider."
        this.closeDisabled = this.awaitingResponse = false
        return
      }

      if (this.newUser.role === "Admin") {
        this.newUser.restaurantIds = []
      }

      if (this.editMode) {
        this.updateUsersAndForm()
      } else {
        await this.createUser(this.newUser)
          .then(() => {
            this.clearFields()
            this.$emit("close")
          })
          .catch(error => {
            this.mainErrorMessage = error
          })
        this.closeDisabled = this.awaitingResponse = false
      }
    },
    updateUsersAndForm() {
      try {
        this.updateUsers(this.itemToEdit.userId, this.newUser)
        this.clearFields()
        this.$emit("close")
      } catch (error) {
        this.mainErrorMessage = error.message
      }
      this.awaitingResponse = this.closeDisabled = false
    },
    clearFields() {
      this.newUser = {
        name: "",
        email: "",
        password: "",
        restaurantIds: [],
        phoneNumber: "",
        role: "",
      }
      this.mainErrorMessage = ""
    },
    prepEdit() {
      Object.assign(this.newUser, this.itemToEdit)
    },
  },
}
</script>
