<template>
  <div>
    <custom-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="New Third Party Type"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      @close="$emit('close')"
      @submit="submitHandle"
    >
      <v-col cols="6">
        <v-text-field v-model="newThirdPartyType.name" label="Name" outlined />
      </v-col>
    </custom-dialog>
  </div>
</template>

<script>
import CustomDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState } from "vuex"

export default {
  name: "add-edit-form",
  components: {
    CustomDialog,
  },
  data() {
    return {
      newThirdPartyType: {
        name: "",
      },
      closeDisabled: false,
      awaitingResponse: false,
    }
  },
  computed: {
    ...mapState(["thirdPartyTypes"]),
    editMode() {
      return this.itemToEdit != null
    },
    itemToEdit() {
      if (this.$route.params.id) {
        return this.thirdPartyTypes.find(item => item.id == this.$route.params.id)
      } else return null
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true
      await this.$store
        .dispatch("updateThirdPartyTypes", this.newThirdPartyType)
        .then(() => {
          this.closeDisabled = this.awaitingResponse = false
          if (this.$route.params.form != null) {
            this.$router.push({ params: { form: null, id: null } })
          }
        })
      this.$emit("close")
    },
    clearFields() {
      this.newThirdPartyType = {}
    },
    prepEdit() {
      Object.assign(this.newThirdPartyType, this.itemToEdit)
    },
  },
}
</script>
