<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      title="Restaurant"
      :editMode="restaurantForm.editMode"
      :isCloseDiabled="restaurantForm.closeDisabled"
      :awaitingResponse="restaurantForm.awaitingResponse"
      :error="restaurantForm.mainErrorMessage"
      @close="restaurantForm.closeForm()"
      @submit="restaurantForm.submitHandle"
    >
      <restaurants-form :id="itemToEditId" ref="restaurantForm" />
    </form-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex"
import RestaurantsForm from "@/AuthenticatedContent/shared/forms/RestaurantsForm.vue"
import FormDialog from "@/AuthenticatedContent/shared/dialogs/FormDialog.vue"

export default {
  name: "add-edit-form",
  components: {
    FormDialog,
    RestaurantsForm,
  },
  data() {
    return {
      restaurantForm: {
        editMode: false,
        closeDisabled: true,
        awaitingResponse: true,
        mainErrorMessage: "",
        closeForm: function () {},
        submitHandle: function () {},
      },
    }
  },
  computed: {
    ...mapState(["restaurants"]),
    itemToEditId() {
      return this.restaurants.find(({ id }) => id === this.$route.params.id)?.id
    },
  },
  mounted() {
    this.restaurantForm = this.$refs.restaurantForm
  },
}
</script>
