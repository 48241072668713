<template>
  <v-card
    v-if="!hideSection"
    class="mt-2 mb-n2 flex-grow-1 py-2"
    color="light-background"
    outlined
  >
    <v-card-title class="text-h6 text-uppercase my-n3">
      <div class="w-100 d-flex justify-space-between pa-0">
        <span class="flex-grow-1">{{ section.title }}</span>
        <v-card-actions>
          <v-btn
            :to="{
              params: { form: 'AddEditForm', id: section.category },
            }"
            color="primary"
            class="my-n2"
          >
            EDIT
          </v-btn>
        </v-card-actions>
      </div>
    </v-card-title>
    <v-card-text>
      <weekly-hours
        v-if="section.category === 'hours'"
        v-model="parsedRows"
        disabled
        class="mt-4"
      />
      <v-list
        v-else
        lines="one"
        :style="{
          backgroundColor: 'transparent !important', // overrides dark bg theme color
        }"
      >
        <settings-row
          v-for="(row, i) in parsedRows"
          :key="i"
          :title="row.title"
          :row="row"
          :value="getFormattedValue(row.value, row.type, row.title)"
          :type="row.type"
          :visibility="row.documents && row.documents[0]"
          :indent="row.indent"
          :fullWidth="section.fullWidth"
        />
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import SettingsRow from "./SettingsRow"
import WeeklyHours from "@/AuthenticatedContent/shared/forms/datetime/WeeklyHours.vue"
import { mapState } from "vuex"
import getSettingsSection from "./mixins"
import formatPrice from "@/mixins/formatPrice"

export default {
  name: "settings-section",
  components: {
    SettingsRow,
    WeeklyHours,
  },
  mixins: [getSettingsSection, formatPrice],
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hideSection: this.section.hide,
    }
  },
  computed: {
    ...mapState(["settings", "restaurants"]),
    parsedRows: {
      get() {
        if (this.section.category === "hours") {
          return this.getSettingsSection(this.section.category)?.[0]?.value
        } else if (this.section.category === "fees" && this.restaurants.length < 2) {
          return this.getSettingsSection(this.section.category)?.filter(
            row => !row.title.includes("Commission")
          )
        } else if (this.section.category === "commissions") {
          const commissions = this.getSettingsSection(this.section.category)?.[0]
            ?.value
          if (!commissions) {
            return []
          }
          return commissions.map(comm => {
            return {
              title: this.$store.getters.getRestaurantName(comm.id),
              value: comm.organizationFee,
              type: "percent",
            }
          })
        }
        return this.getSettingsSection(this.section.category)
      },
      set(value) {
        console.error("Cannot set parsedRows", value)
      },
    },
  },
  mounted() {
    if (this.section.category === "commissions") {
      const commissions = this.getSettingsSection(this.section.category)?.[0]?.value
      if (!commissions) {
        this.hideSection = true
      }
    }
  },
  methods: {
    getFormattedValue(value, type, title) {
      if ((title === "Test Mode" && !value) || value === "production") {
        return "Off"
      } else if (value === null || value === undefined || value === "") {
        return "-"
      } else if (type === "percent") {
        return value + "%"
      } else if (type === "dollar") {
        return this.formatPrice(value)
      } else if (value === "sandbox") {
        return "On"
      }
      return value
    },
  },
}
</script>
