<template>
  <div v-if="$route.params.tab == 'users'">
    <page-component
      pageTitle="Users"
      :headers="userHeaders"
      :items="filteredUsers"
      :loadingData="usersLoaded"
      :buttons="[
        { text: '+ Add New User', to: { params: { form: 'AddEditForm' } } },
      ]"
    >
      <template v-slot:[`item.restaurantIds`]="props">
        {{
          props.item.restaurantIds.length > 0
            ? restaurantNames(props.item.restaurantIds)
            : "All Restaurants"
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="edit" @click="editUser(item)" />
        <nxg-action-btn
          :disabled="!item.email"
          type="email-fast"
          confirm="Are you sure you want to send a password reset email to this user?"
          @click="resetPassword(item)"
        />
        <nxg-action-btn type="delete" item-type="USER" :item="item" />
      </template>
    </page-component>
    <add-edit-form @close="closeForm" />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import { mapState, mapActions, mapGetters } from "vuex"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import PageComponent from "../../shared/PageComponent.vue"

export default {
  name: "users",
  components: { AddEditForm, PageComponent },
  data() {
    return {
      showForm: false,
      userToEdit: {},
      userEditMode: false,
      searchUsers: "",
      selectedUsers: [],
      usersLoaded: false,
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName"]),
    ...mapState(["currentUserAccess", "users", "restaurants"]),
    userHeaders() {
      const headers = [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
      ]
      if (this.currentUserAccess.role === "Admin") {
        headers.push({ text: "Restaurants", value: "restaurantIds" })
      }
      headers.push({ text: "Role", value: "role", align: "center" })
      headers.push({
        text: "Actions",
        value: "actions",
        sortable: false,
      })
      return headers
    },
    filteredUsers() {
      if (this.users) {
        let usersArray = []
        Object.keys(this.users)?.forEach(userId => {
          const thisUser = this.users[userId]
          thisUser.userId = userId
          usersArray.push(thisUser)
        })
        if (this.currentUserAccess.restaurantIds?.length) {
          usersArray = usersArray.filter(user => {
            return (
              this.currentUserAccess.restaurantIds.filter(accessRestaurant =>
                user.restaurantIds.includes(accessRestaurant)
              )?.length > 0
            )
          })
        }
        return usersArray.sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return b.createdAt.seconds - a.createdAt.seconds
          }
          return 0
        })
      }
      return []
    },
  },
  methods: {
    ...mapActions(["fetchUsers"]),
    restaurantNames(restaurantIds) {
      var nameArray = []
      restaurantIds.forEach(id => {
        nameArray.push(this.getRestaurantName(id))
      })
      return nameArray.join(", ")
    },
    async editUser(user) {
      await this.$store.commit("setItemToEdit", user)
      this.$router.push({ params: { form: "AddEditForm", id: user.uid } })
    },
    closeForm() {
      this.fetchUsers()
      this.$router.push({ params: { form: null, id: null }, query: {} })
    },
    resetPassword(user) {
      const email = user.email
      sendPasswordResetEmail(getAuth(), email)
        .then(() => {
          alert("Password reset email sent.")
        })
        .catch(error => {
          alert("Sorry, there was an error sending the password reset email.")
          console.error("Password reset error", error)
        })
    },
  },
}
</script>
