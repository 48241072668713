<template>
  <custom-dialog
    :visible="$route.params.form == 'AddEditForm'"
    title="Settings"
    :closeDisabled="closeDisabled"
    :awaitingResponse="awaitingResponse"
    @close="closeForm()"
    @submit="submitHandle"
  >
    <v-row v-if="imageRows.length > 0">
      <v-col v-for="(row, i) in imageRows" :key="i" cols="12" md="6">
        <v-card flat color="light-background">
          <v-card-title class="text-h6 text-uppercase d-flex justify-center">
            {{ row.title }}
          </v-card-title>
          <v-card-text>
            <custom-image-upload
              name="image"
              :location="'Organization/' + row.path[0]"
              :imageUrl="row.value || ''"
              small
              allow-edit
              @imageProcessed="row.value = $event"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col v-if="$route.params.id === 'hours'" cols="12">
        <weekly-hours
          v-model="newSettings[0].value"
          :disabled="!newSettings[0].value"
        />
      </v-col>
      <v-col
        v-for="(row, i) in mainSettings"
        :key="i"
        cols="12"
        md="6"
        lg="4"
        :class="row.type === 'boolean' ? '' : 'mt-2'"
      >
        <color-picker
          v-if="row.type === 'color'"
          v-model="row.value"
          :label="row.title"
        />
        <div v-else>
          <div v-if="row.type === 'boolean'">
            <div class="mt-n2">
              <small>{{ row.title }}</small>
            </div>
            <v-switch
              v-model="row.value"
              class="mt-0"
              color="success"
              :label="getSwitchLabel(row.value)"
              :true-value="$route.params.id === 'payments' ? 'sandbox' : true"
              :false-value="$route.params.id === 'payments' ? 'production' : false"
              dense
            />
          </div>
          <v-phone-input
            v-else-if="row.type === 'phone'"
            v-model="row.value"
            outlined
            :label="row.title"
          />
          <v-text-field
            v-else-if="
              row.type === 'percent' ||
              row.type === 'number' ||
              row.type === 'dollar'
            "
            v-model.number="row.value"
            :append-outer-icon="
              $route.params.id === 'commissions' ? 'mdi-minus-circle-outline' : ''
            "
            :type="row.type ? (row.type === 'percent' ? 'number' : 'text') : 'text'"
            :suffix="row.type === 'percent' ? '%' : ''"
            outlined
            :label="row.title"
            @click:append-outer="removeCommission(row)"
          />
          <v-text-field
            v-else-if="
              row.title.includes('Secret') || row.title.includes('Password')
            "
            v-model="row.value"
            type="password"
            outlined
            :label="row.title"
          />
          <v-text-field
            v-else
            v-model="row.value"
            :type="row.type"
            outlined
            :label="row.title"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$route.params.id === 'commissions'" class="mt-n6 mx-n6">
      <v-col>
        <v-select
          v-model="commissionToAdd"
          label="Commission to add"
          :append-outer-icon="'mdi-plus-circle-outline'"
          :items="filteredRestaurants"
          :menu-propsss="{ bottom: true, offsetY: true }"
          outlined
          hide-details="auto"
          item-value="id"
          item-text="name"
          return-object
          @click:append-outer="addCommission(commissionToAdd)"
        />
      </v-col>
    </v-row>
  </custom-dialog>
</template>

<script>
import ColorPicker from "../../shared/forms/ColorPicker.vue"
import CustomDialog from "../../shared/dialogs/FormDialog.vue"
import CustomImageUpload from "../../shared/CustomImageUpload.vue"
import WeeklyHours from "@/AuthenticatedContent/shared/forms/datetime/WeeklyHours.vue"
import { mapState } from "vuex"
import getSettingsSection from "./mixins"

export default {
  name: "add-edit-form",
  components: {
    ColorPicker,
    CustomDialog,
    CustomImageUpload,
    WeeklyHours,
  },
  mixins: [getSettingsSection],
  data() {
    return {
      newSettings: [],
      closeDisabled: false,
      awaitingResponse: false,
      commissionToAdd: "",
    }
  },

  computed: {
    ...mapState(["settings", "restaurants"]),
    fieldRows() {
      if (this.$route.params.id) {
        if (this.$route.params.id === "commissions") {
          return this.getSettingsSection(this.$route.params.id)[0].value.map(
            comm => {
              return {
                title: this.$store.getters.getRestaurantName(comm.id),
                value: comm.organizationFee,
                type: "percent",
                id: comm.id,
              }
            }
          )
        }
        return JSON.parse(
          JSON.stringify(this.getSettingsSection(this.$route.params.id)) // resets object reference every time form closed
        )
      }
      return []
    },
    imageRows() {
      return this.fieldRows.filter(row => row.type === "image")
    },
    mainSettings() {
      return this.fieldRows.filter(row => row.type !== "image")
    },
    filteredRestaurants() {
      return this.restaurants.filter(
        restaurant => !this.newSettings.find(comm => comm.id == restaurant.id)
      )
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          this.prepEdit()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true
      const formattedSettings =
        this.$route.params.id === "commissions"
          ? JSON.parse(
              JSON.stringify(this.getSettingsSection(this.$route.params.id))
            )
          : this.newSettings
      if (this.$route.params.id === "commissions") {
        // reformats the settings object for commissions since it's an array in one field in the settings
        formattedSettings[0].value = this.newSettings.map(comm => {
          const newComm = {
            id: comm.id,
          }
          if (comm.value) {
            newComm.organizationFee = comm.value
          }
          return newComm
        })
      }
      await this.$store.dispatch("updateSettings", formattedSettings).then(() => {
        this.closeDisabled = this.awaitingResponse = false
        if (this.$route.params.form != null) {
          this.$router.push({ params: { form: null, id: null } })
        }
      })
      this.closeDisabled = this.awaitingResponse = false
      this.$emit("close")
    },
    closeForm() {
      this.$router.push({ params: { form: null, id: null } })
    },
    prepEdit() {
      this.newSettings = this.fieldRows
    },
    removeCommission(row) {
      this.newSettings = this.newSettings.filter(comm => comm.id !== row.id)
    },
    addCommission(row) {
      this.newSettings.push({
        title: row.name,
        value: 0,
        type: "percent",
        id: row.id,
      })
      this.commissionToAdd = ""
    },
    getSwitchLabel(value) {
      if (this.$route.params.id === "payments") {
        return value === "sandbox" ? "On" : "Off"
      }
      return value ? "Enabled" : "Disabled"
    },
  },
}
</script>
