<template>
  <div>
    <custom-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="Tax"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :submitDisabled="editDisabled"
      @close="close"
      @submit="submitHandle()"
    >
      <v-row justify="start" class="mt-2">
        <v-col cols="4">
          <v-text-field
            v-model="newTax.name"
            label="Tax Name"
            outlined
            :disabled="editDisabled"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="newTax.restaurantIds"
            :items="restaurantIds"
            label="Restaurants"
            multiple
            outlined
            :disabled="editDisabled"
          />
        </v-col>
        <v-col cols="4" />
        <v-col cols="4">
          <v-select
            v-model="newTax.type"
            :items="taxTypes"
            outlined
            label="Select Tax Type"
            :disabled="editDisabled"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="newTax.amount"
            :label="
              !newTax.type
                ? 'Tax Amount (% or $)'
                : newTax.type === 'Percentage'
                ? 'Tax Amount (%)'
                : 'Tax Amount ($)'
            "
            outlined
            :disabled="editDisabled"
          />
        </v-col>
        <v-col cols="4">
          <v-checkbox
            v-model="newTax.isCompound"
            label="Compound Tax"
            color="primary"
            :disabled="editDisabled"
          />
        </v-col>
      </v-row>
    </custom-dialog>
  </div>
</template>

<script>
import CustomDialog from "../../shared/dialogs/FormDialog.vue"
import { mapGetters, mapState } from "vuex"

export default {
  name: "add-edit-form",
  components: { CustomDialog },
  data() {
    return {
      closeDisabled: false,
      awaitingResponse: false,
      newTax: {
        name: "",
        type: "",
        amount: 0,
        isCompound: false,
        restaurantIds: [],
      },
      taxTypes: ["Percentage", "Fixed Amount"],
    }
  },
  computed: {
    ...mapState(["itemToEdit", "restaurants"]),
    ...mapGetters(["getRestaurantSyncStatus"]),
    editMode() {
      return this.itemToEdit != null
    },
    editDisabled() {
      return (
        this.editMode &&
        this.itemToEdit.restaurantIds?.some(r => !this.getRestaurantSyncStatus(r.id))
      )
    },
    restaurantIds() {
      return this.restaurants.map(r => {
        return {
          value: r.id,
          text: r.name,
          disabled: this.getRestaurantSyncStatus(r.id),
        }
      })
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    submitHandle() {
      //if (!this.$v.$invalid) {
      this.closeDisabled = this.awaitingResponse = true
      this.$store
        .dispatch("updateTaxes", this.newTax)
        .then((this.closeDisabled = this.awaitingResponse = false))
      this.newTax = {}
      this.close()
    },
    prepEdit() {
      Object.assign(this.newTax, this.itemToEdit)
    },
    clearFields() {
      this.newTax = {}
    },
    close() {
      this.$router.push({ params: { form: null, id: null } })
      this.$emit("close")
    },
  },
}
</script>
