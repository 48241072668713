<template>
  <div v-if="$route.params.tab == 'channels'">
    <page-component
      pageTitle="Payment Channels"
      :restaurantFilterAvailable="true"
      :headers="headers"
      :items="filteredChannels"
      :loadingData="false"
      :buttons="buttons"
    >
      <template v-if="isNextGenUser" v-slot:filters[0]>
        <v-checkbox
          v-model="showAllChannels"
          label="Show all channels"
          class="my-1 ml-2"
          hide-details
        />
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name ? item.name : "N/A" }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getItemType(item) }}
        <span v-if="item.platformAccount">(Platform)</span>
      </template>
      <template v-slot:[`item.lastUpdated`]="{ item }">
        {{ formatDate(item) }}
      </template>
      <template v-slot:[`item.restaurants`]="{ item }">
        <span v-if="item.restaurantIds">
          {{ restaurantNames(item.restaurantIds) }}
        </span>
        <span v-else-if="item.restaurantId">
          {{ getRestaurantName(item.restaurantId) }}
        </span>
        <span v-else-if="item.restaurantData">
          {{ getRestaurantName(item.restaurantData.map(data => data.restaurantId)) }}
        </span>
        <span v-else>{{ item.organizationAccount ? "Organization" : "N/A" }}</span>
      </template>
      <template v-if="isNextGenUser" v-slot:[`item.actions`]="{ item }">
        <v-menu
          v-if="isNextGenUser"
          class="mr-2"
          open-on-click
          offset-x
          offset-y
          bottom
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text small icon v-bind="attrs" v-on="on">
              <v-icon>mdi-code-tags</v-icon>
            </v-btn>
          </template>
          <v-card height="400">
            <v-card-text style="background: var(--v-background-base)">
              <json-tree :raw="JSON.stringify(item)" />
            </v-card-text>
          </v-card>
        </v-menu>
        <nxg-action-btn
          v-if="isPaymentChannel(item)"
          type="edit"
          @click="editItem(item)"
        />
      </template>
    </page-component>
    <add-edit-form :paymentProviders="paymentProviders" @close="formClosed()" />
    <v-dialog v-model="needsRefresh" persistent retain-focus width="500">
      <v-card>
        <v-card-title>Done</v-card-title>
        <v-card-text class="pb-6">
          <p>
            Your channel has been successfully added to Restaurant Manager. Please
            press the button below to update the page.
          </p>
          <v-btn color="primary" :loading="awaitingResponse" @click="refreshData()">
            Continue
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import moment from "moment"
import AddEditForm from "./AddEditForm.vue"
import PageComponent from "../../shared/PageComponent.vue"
import JsonTree from "vue-json-tree"

export default {
  name: "channels",
  components: { PageComponent, AddEditForm, JsonTree },
  data() {
    return {
      showAllChannels: false,
      awaitingResponse: false,
      needsRefresh: false,
      paymentProviders: ["Stripe"],
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName", "isNextGenUser"]),
    ...mapState(["channels", "restaurants", "settings"]),
    headers() {
      const headers = [
        { text: "Type", value: "type", width: 150 },
        { text: "Restaurant(s)", value: "restaurants", width: 200 },
        { text: "Account Id", value: "accountId" },
        { text: "Last Updated", value: "lastUpdated", width: 200 },
      ]
      if (this.isNextGenUser) {
        headers.push({ text: "Actions", value: "actions" })
      }
      return headers
    },
    filteredChannels() {
      if (this.showAllChannels && this.isNextGenUser) return this.channels
      return this.channels.filter(channel => this.isPaymentChannel(channel))
    },
    buttons() {
      if (this.paymentProviders.length && this.restaurantsWithoutChannels.length) {
        return [
          {
            text: "+ Add New Payment Channel",
            to: { params: { form: "AddEditForm" } },
          },
        ]
      } else return []
    },
    restaurantsWithoutChannels() {
      return this.restaurants.filter(
        restaurant =>
          !this.channels.some(channel => channel.restaurantId === restaurant.id)
      )
    },
  },
  mounted() {
    if (!this.settings.stripeSecretKey) {
      // checks for existence of an organization Stripe account, if not, remove Stripe as an option
      const index = this.paymentProviders.indexOf("Stripe")
      if (index > -1) {
        this.paymentProviders.splice(index, 1)
      }
    }
  },
  methods: {
    ...mapActions(["fetchChannels"]),
    formatDate(channel) {
      if (!channel.lastUpdated) return "-"
      return moment(channel.lastUpdated.toDate()).format("MMM D, YY @ LT")
    },
    getItemType(item) {
      return item.type
        ? item.type[0].toUpperCase() + item.type.slice(1)
        : item.name === "SquareRestaurant"
        ? "Square"
        : "Other"
    },
    isPaymentChannel(channel) {
      return ["Square", "Stripe"].includes(this.getItemType(channel))
    },
    restaurantNames(channel) {
      if (channel.restaurantData?.length > 0) {
        var restaurantNames = []
        channel.restaurantData.forEach(resto => {
          restaurantNames.push(this.getRestaurantName(resto.restaurantId))
        })
        return restaurantNames.join(", ")
      }
      return "All Restaurants"
    },
    async editItem(channel) {
      await this.$store.commit("setItemToEdit", channel)
      this.$router.push({ params: { form: "AddEditForm", id: channel.id } })
    },
    formClosed() {
      this.fetchChannels()
      setTimeout(() => {
        this.needsRefresh = true
      }, 500)
    },
    async refreshData() {
      this.awaitingResponse = true

      await this.fetchChannels()

      this.needsRefresh = false
      this.awaitingResponse = false
    },
  },
}
</script>
