var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('div',[_c('v-card',{attrs:{"id":"inventoryCategory","elevation":"1"}},[_c('v-card-title',[_vm._v("Inventory Categories")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{on:{"click":function($event){_vm.newCategory = !_vm.newCategory}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.newCategory),expression:"newCategory"}],attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Category Name","outlined":"","dense":""},model:{value:(_vm.saveCategory),callback:function ($$v) {_vm.saveCategory=$$v},expression:"saveCategory"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.newCategory),expression:"newCategory"}],attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.updateCategories(_vm.saveCategory)
                _vm.saveCategory = null
                _vm.newCategory = !_vm.newCategory}}},[_vm._v(" Save ")])],1)],1),_c('v-row',_vm._l((_vm.categories),function(category,i){return _c('v-col',{key:i,attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(category)+" ")])}),1)],1)],1),_c('v-card',{staticClass:"mt-5",attrs:{"id":"subcategory","elevation":"1"}},[_c('v-card-title',[_vm._v("Subcategories")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{on:{"click":function($event){_vm.newSubcategory = !_vm.newSubcategory}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.newSubcategory),expression:"newSubcategory"}],attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Subcategory","outlined":"","dense":""},model:{value:(_vm.saveSubcategory),callback:function ($$v) {_vm.saveSubcategory=$$v},expression:"saveSubcategory"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.newSubcategory),expression:"newSubcategory"}],attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.updateSubcategories(_vm.saveSubcategory)
                _vm.saveSubcategory = null
                _vm.newSubcategory = !_vm.newSubcategory}}},[_vm._v(" Save ")])],1)],1),_c('v-row',_vm._l((_vm.subcategories),function(subcategory,i){return _c('v-col',{key:i,attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(subcategory)+" ")])}),1)],1)],1),_c('v-card',{staticClass:"mt-5",attrs:{"elevation":"1"}},[_c('v-card-title',[_vm._v("Suppliers")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{on:{"click":function($event){_vm.newSupplier = !_vm.newSupplier}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.newSupplier),expression:"newSupplier"}],attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Name","single-line":"","outlined":"","dense":""},model:{value:(_vm.supplierName),callback:function ($$v) {_vm.supplierName=$$v},expression:"supplierName"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.newSupplier),expression:"newSupplier"}],attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Phone Number","single-line":"","outlined":"","dense":""},model:{value:(_vm.supplierNumber),callback:function ($$v) {_vm.supplierNumber=$$v},expression:"supplierNumber"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.newSupplier),expression:"newSupplier"}],attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Email","single-line":"","outlined":"","dense":""},model:{value:(_vm.supplierEmail),callback:function ($$v) {_vm.supplierEmail=$$v},expression:"supplierEmail"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.newSupplier),expression:"newSupplier"}],attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.updateSuppliers({
                  email: _vm.supplierEmail,
                  name: _vm.supplierName,
                  phoneNumber: _vm.supplierNumber,
                  id: _vm.uuidv4(),
                })
                _vm.supplierName = _vm.supplierNumber = _vm.supplierEmail = null
                _vm.newSupplier = !_vm.newSupplier}}},[_vm._v(" Save ")])],1)],1),_c('v-row',_vm._l((_vm.suppliers),function(supplier,i){return _c('v-col',{key:i,attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(supplier.name)+" ")])}),1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }