<template>
  <div v-if="$route.params.tab == 'taxes'">
    <page-component
      pageTitle="Taxes"
      :restaurantFilterAvailable="true"
      :headers="taxHeaders"
      :items="taxes"
      :loadingData="taxesLoaded"
      :buttons="buttons"
    >
      <template v-slot:[`item.restaurantIds`]="{ item }">
        {{
          item.restaurantIds
            ? item.restaurantIds
                .filter(restaurantId => getRestaurantName(restaurantId) !== "N/A")
                .map(restaurantId => getRestaurantName(restaurantId))
                .join(", ")
            : "N/A"
        }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ item.amount }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ item.type }}
      </template>
      <template v-if="userCanEdit" v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="edit" @click="editItem(item)" />
        <nxg-action-btn
          v-if="item.restaurantIds.every(restId => editEnabled(restId))"
          type="delete"
          item-type="TAX"
          :item="item"
        />
      </template>
    </page-component>
    <add-edit-form v-if="userCanEdit" @close="showAddNewTax = false" />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import { mapState, mapGetters, mapActions } from "vuex"
import Sortable from "sortablejs"
import PageComponent from "../../shared/PageComponent.vue"

export default {
  name: "taxes",
  components: {
    AddEditForm,
    PageComponent,
  },
  directives: {
    sortableDataTable: {
      bind(el, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event)
          },
        }
        Sortable.create(el.getElementsByTagName("tbody")[0], options)
      },
    },
  },
  data() {
    return {
      taxHeaders: [
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "Type", value: "type" },
        { text: "Restaurant", value: "restaurantIds" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      showAddNewTax: false,
      editMode: false,
      searchTerm: "",
      itemToEdit: {},
      taxesLoaded: false,
    }
  },
  computed: {
    ...mapState(["taxes", "restaurants"]),
    ...mapGetters(["getRestaurantName", "getRestaurantSyncStatus", "userCanEdit"]),
    buttons() {
      const addTaxButton = {
        text: "+ Add New Tax",
        to: { params: { form: "AddEditForm" } },
      }
      // Only allow adding taxes to restaurants that are not synced with Square
      if (
        this.userCanEdit &&
        this.restaurants?.some(r => !this.getRestaurantSyncStatus(r.id))
      ) {
        return [addTaxButton]
      } else return []
    },
  },
  methods: {
    ...mapActions(["deleteTax", "updateTaxOrder"]),
    editEnabled(restaurantId) {
      return !this.getRestaurantSyncStatus(restaurantId)
    },
    async editItem(tax) {
      await this.$store.commit("setItemToEdit", tax)
      this.$router.push({ params: { form: "AddEditForm", id: tax.id } })
    },
    saveOrder(event) {
      const movedItem = this.taxes.splice(event.oldIndex, 1)[0]
      this.taxes.splice(event.newIndex, 0, movedItem)
      let medTaxes = []
      for (let i = 0; i < this.taxes.length; i++) {
        medTaxes.push(this.taxes[i])
        medTaxes[i].order = i
      }
      this.updateTaxOrder(medTaxes)
    },
  },
}
</script>
<style lang="scss">
.table-card,
.header-style {
  background-color: var(--v-primary-base);
}
</style>
