<template>
  <div>
    <custom-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="Promo Image or Video"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      @close="$emit('close')"
      @submit="submitHandle"
    >
      <h3 class="my-5">Basic Information</h3>
      <v-row justify="start" class="mt-4">
        <v-col cols="6">
          <v-text-field
            v-model="newPromoImage.title"
            label="Title"
            class="mb-2"
            hide-details="auto"
            outlined
          />
          <v-text-field
            v-model="newPromoImage.subtitle"
            label="Subtitle"
            outlined
            hide-details="auto"
          />
          <v-switch
            v-if="false"
            v-model="newPromoImage.hideTitle"
            :label="
              newPromoImage.hideTitle
                ? 'Hide title on display'
                : 'Show title on display'
            "
            hide-details
            class="mt-n4"
          />
          <h3 class="my-10">Timing</h3>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newPromoImage.startSec"
                type="number"
                label="Start"
                suffix="ms"
                class="mb-2"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="newPromoImage.duration"
                type="number"
                label="Duration"
                suffix="ms"
                class="mb-2"
                hide-details="auto"
                outlined
              />
            </v-col>
          </v-row>
          <h3 class="my-10">Display</h3>
          <v-row>
            <v-col>
              <v-switch
                v-model="newPromoImage.containImage"
                :label="newPromoImage.containImage ? 'Contain Image' : 'Fill Screen'"
                hide-details
                class="mt-n4"
              />
            </v-col>
            <v-col v-if="newPromoImage.containImage" class="mt-n2 py-1">
              <color-picker
                v-model="newPromoImage.backgroundColor"
                label="Background Color"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="d-flex flex-column mt-n10">
          <custom-image-upload
            name="promoImages"
            :imageUrl="newPromoImage.imageUrl || ''"
            allow-edit
            allow-video
            @imageProcessed="newPromoImage.imageUrl = $event"
          />
          <v-switch
            v-model="newPromoImage.isVideo"
            :label="newPromoImage.isVideo ? 'Video' : 'Image'"
            hide-details
            class="align-self-center"
          />
        </v-col>
      </v-row>
    </custom-dialog>
  </div>
</template>

<script>
import ColorPicker from "../../shared/forms/ColorPicker.vue"
import CustomDialog from "../../shared/dialogs/FormDialog.vue"
import CustomImageUpload from "../../shared/CustomImageUpload.vue"
import { mapState } from "vuex"

export default {
  name: "add-edit-form",
  components: {
    ColorPicker,
    CustomDialog,
    CustomImageUpload,
  },
  data() {
    return {
      defaultPromoImage: {
        title: "",
        subtitle: "",
        imageUrl: "",
        startSec: 0,
        duration: 0,
        containImage: true,
        backgroundColor: "#000000",
        hideTitle: false,
        isVideo: false,
      },
      newPromoImage: { ...this.defaultPromoImage },
      showColorPicker: false,
      closeDisabled: false,
      awaitingResponse: false,
    }
  },
  computed: {
    ...mapState(["restaurants", "promoImages"]),
    editMode() {
      return this.itemToEdit != null
    },
    itemToEdit() {
      if (this.$route.params.id) {
        return this.promoImages.find(item => item.id == this.$route.params.id)
      } else return null
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true
      await this.$store
        .dispatch("updatePromoImages", this.newPromoImage)
        .then(() => {
          this.closeDisabled = this.awaitingResponse = false
          if (this.$route.params.form != null) {
            this.$router.push({ params: { form: null, id: null } })
          }
        })
      this.$emit("close")
    },
    clearFields() {
      this.newPromoImage = { ...this.defaultPromoImage }
    },
    prepEdit() {
      Object.assign(this.newPromoImage, this.itemToEdit)
    },
  },
}
</script>
