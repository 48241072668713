export default {
  methods: {
    getFieldError(field) {
      if (!field) return ""
      if (!field.$dirty) return ""
      if (!field.$error) return ""
      if (!field.required) return "This field is required"
      if (!field.numeric) return "This field must be a number"
      return ""
    },
  },
}
