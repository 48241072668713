<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      title="Restaurant"
      :editMode="editMode"
      :isCloseDiabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      @close="clearFields()"
      @submit="submitHandle"
    >
      <h3>Restaurant Information</h3>
      <v-row justify="start" class="mt-3">
        <v-col cols="4">
          <v-text-field
            v-model="name"
            label="Restaurant Name"
            outlined
            :disabled="!fieldsEnabled"
            :error-messages="getFormErrors($v, 'name')"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="name"
            label="Restaurant Name"
            outlined
            :disabled="!fieldsEnabled"
            :error-messages="getFormErrors($v, 'name')"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="prepTime"
            label="Prep Time"
            suffix="min"
            outlined
            number
            :error-messages="getFormErrors($v, 'prepTime')"
          />
        </v-col>
        <v-col v-if="false" cols="4">
          <v-text-field
            v-model="storeNumber"
            label="Store Number"
            outlined
            number
            :disabled="!fieldsEnabled"
            :error-messages="getFormErrors($v, 'storeNumber')"
          />
        </v-col>
        <v-col v-if="false" cols="3">
          <v-menu :close-on-content-click="false" :offset-y="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="pa-7" v-bind="attrs" v-on="on">
                + Features
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="enabledFeatures.delivery" />
                </v-list-item-action>
                <v-list-item-title>Delivery</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="enabledFeatures.multiLocation" />
                </v-list-item-action>
                <v-list-item-title>Multi Location</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch />
                </v-list-item-action>
                <v-list-item-title>Auto-sync with Square</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <h3>Social Media</h3>
      <v-row justify="start" class="mt-3">
        <v-col cols="4">
          <v-select
            v-model="social.type"
            :items="socialTypes"
            item-value="id"
            item-text="name"
            label="Select Platform"
            outlined
            :error-messages="getFormErrors($v, 'social.type')"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="social.handle"
            label="Handle"
            outlined
            :error-messages="getFormErrors($v, 'social.handle')"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="social.url"
            label="URL"
            outlined
            :error-messages="getFormErrors($v, 'social.url')"
          />
        </v-col>
      </v-row>
      <h3 class="mb-4">Hours</h3>
      <weekly-hours
        :editMode="editMode"
        :hoursToEdit="hours"
        :error-messages="getFormErrors($v, 'hours')"
        :disabled="!fieldsEnabled"
        @change="Object.assign(hours, $event)"
      />
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState, mapActions, mapGetters } from "vuex"
import WeeklyHours from "../../shared/forms/datetime/WeeklyHours.vue"
import { required, numeric } from "vuelidate/lib/validators"

export default {
  name: "add-edit-form",
  components: {
    FormDialog,
    WeeklyHours,
  },
  data() {
    return {
      name: "",
      id: null,
      storeNumber: "",
      prepTime: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "",
        latitude: "",
        longitude: "",
        postCode: "",
        provinceState: "",
      },
      hours: {},
      enabledFeatures: {
        delivery: false,
        multiLocation: false,
      },
      social: {
        type: "",
        handle: "",
        url: "",
      },
      closeDisabled: false,
      awaitingResponse: false,
      socialTypes: [
        { name: "Facebook", id: "facebook" },
        { name: "Twitter", id: "twitter" },
        { name: "Instagram", id: "instagram" },
      ],
      mainErrorMessage: "",
    }
  },
  validations() {
    return {
      name: { required },
      storeNumber: {},
      prepTime: { numeric },
      address: {},
      hours: {},
      enabledFeatures: {},
      social: {},
    }
  },
  computed: {
    ...mapState["restaurants"],
    ...mapGetters(["getItemToEdit", "getRestaurantSyncStatus"]),
    editMode() {
      return this.itemToEdit != null
    },
    itemToEdit() {
      return this.getItemToEdit
    },
    fieldsEnabled() {
      if (!this.editMode) {
        return true
      }
      return !this.getRestaurantSyncStatus(this.itemToEdit.id)
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["updateRestaurants", "addRestaurant"]),
    async submitHandle() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.closeDisabled = this.awaitingResponse = true
        const fields = {
          id: "string",
          name: "string",
          storeNumber: "string",
          prepTime: "number",
          address: "object",
          hours: "object",
          enabledFeatures: "object",
          social: "object",
        }
        var payload = {}
        for (const [field, type] of Object.entries(fields)) {
          switch (type) {
            case "number":
              payload[field] = parseFloat(this[field])
              break
            case "object":
              payload[field] = this[field]
              break
            case "array":
              payload[field] = this[field]?.length > 0 ? this[field] : []
              break
            default:
              if (this[field] !== null && this[field] !== undefined) {
                payload[field] = this[field]
              } else {
                payload[field] = ""
              }
              break
          }
        }
        if (this.editMode) {
          await this.updateRestaurants(payload)
            .then(() => {
              this.$router.push({ params: { form: null, id: null } })
              this.clearFields()
            })
            .catch(err => {
              this.mainErrorMessage = err
            })
        } else if (!this.editMode) {
          await this.addRestaurant(payload)
            .then(() => {
              this.$router.push({ params: { form: null, id: null } })
              this.clearFields()
            })
            .catch(err => {
              this.mainErrorMessage = err
            })
        }
      }
    },
    clearFields() {
      this.id = this.name = this.storeNumber = this.prepTime = ""
      this.awaitingResponse = this.closeDisabled = false
      this.address = {
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "",
        latitude: "",
        longitude: "",
        postCode: "",
        provinceState: "",
      }
      this.hours = {}
      this.enabledFeatures = {
        delivery: false,
        multiLocation: false,
      }
      this.social = {
        type: "",
        handle: "",
        url: "",
      }
      this.$v.$reset()
      this.mainErrorMessage = ""
    },
    getAddressData(addy) {
      this.address.addressLine1 = addy.street_number + " " + addy.route
      this.address.city = addy.locality
      this.address.country = addy.country
      this.address.postCode = addy.postal_code
      this.address.provinceState = addy.administrative_area_level_1
      this.address.latitude = addy.latitude
      this.address.longitude = addy.longitude
    },
    prepEdit() {
      this.name = this.itemToEdit.name
      this.storeNumber = this.itemToEdit.storeNumber
      this.prepTime = this.itemToEdit.prepTime
      this.address = this.itemToEdit.address
      this.id = this.itemToEdit.id
      this.enabledFeatures = this.itemToEdit.enabledFeatures
      this.social = this.itemToEdit.social
      this.hours = this.itemToEdit.hours
        ? this.itemToEdit.hours
        : {
            sun: [{ start: 0, end: 0 }],
            mon: [{ start: 0, end: 0 }],
            tue: [{ start: 0, end: 0 }],
            wed: [{ start: 0, end: 0 }],
            thu: [{ start: 0, end: 0 }],
            fri: [{ start: 0, end: 0 }],
            sat: [{ start: 0, end: 0 }],
          }
    },
  },
}
</script>
