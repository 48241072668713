<template>
  <div v-if="$route.params.tab == 'settings'">
    <v-row class="mt-2 mb-n4">
      <v-col cols="12" md="6">
        <h2 class="text-uppercase page-title mb-4">Settings</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(section, i) in filteredSections"
        :key="i"
        cols="12"
        :md="section.fullWidth ? 12 : 6"
        class="d-flex"
      >
        <settings-section :section="section" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <small class="ml-5 text--lighttext">
          ID: {{ firebaseRefs.organization.id }}
        </small>
      </v-col>
    </v-row>
    <add-edit-form />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import SettingsSection from "./SettingsSection.vue"
import mixins from "./mixins.js"
import { mapGetters, mapState } from "vuex"

export default {
  name: "settings",
  components: {
    AddEditForm,
    SettingsSection,
  },
  mixins: [mixins],
  computed: {
    ...mapState(["settings", "restaurants", "firebaseRefs"]),
    ...mapGetters(["isNextGenUser"]),
    filteredSections() {
      return this.sections.filter(section => {
        return section.hide === undefined || section.hide !== true
      })
    },
    sections() {
      const defaultSections = [
        {
          title: "About",
          category: "about",
        },
        {
          title: "Organization Hours",
          category: "hours",
          hide: this.restaurants.length < 2,
        },
        {
          title: "Colors",
          category: "colors",
        },
        {
          title: "Display",
          category: "display",
        },
        {
          title: "App Options",
          category: "appAndCheckout",
        },
        {
          title: "Payments",
          category: "payments",
          hide: !this.isNextGenUser,
        },
        {
          title: "Pricing",
          category: "fees",
        },
        {
          title: "Customized Commissions",
          category: "commissions",
          hide: this.restaurants.length < 2 || !this.settings?.commissions?.enabled,
        },
        {
          title: "Delivery",
          category: "delivery",
          hide: !this.settings?.delivery?.enabled,
        },
        {
          title: "Referral",
          category: "referral",
        },
      ]
      Object.keys(this.fieldsBySection).forEach(key => {
        if (
          defaultSections.find(section => section.category === key) ||
          key === "other"
        )
          return
        defaultSections.push({
          title: this.keyToWord(key),
          category: key,
        })
      })
      defaultSections.push({
        title: "Other",
        category: "other",
        fullWidth: true,
      })
      return defaultSections
    },
  },
}
</script>
