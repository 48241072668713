<template>
  <form-dialog
    :visible="$route.params.form == 'AddEditForm'"
    title="Reward"
    :editMode="editMode"
    :isCloseDiabled="closeDisabled"
    :awaitingResponse="awaitingResponse"
    :error="mainErrorMessage"
    @close="clearFields()"
    @submit="submitHandle"
  >
    <v-row justify="start" class="mt-2">
      <v-col cols="12">
        <h3>Basic Information</h3>
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col cols="6">
        <v-select
          v-model="reward.type"
          :items="rewardTypes"
          item-text="text"
          item-value="value"
          outlined
          label="Reward Type"
        />
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          v-if="reward.type === 'freeItem'"
          v-model="reward.menuItemId"
          :items="menuItems"
          item-text="name"
          item-value="id"
          outlined
          label="Menu Item"
        />
        <v-text-field
          v-if="reward.type === 'fixedDiscount'"
          v-model.number="reward.fixedDiscountAmount"
          prefix="$"
          label="Discount"
          outlined
          :error-messages="getFieldError($v.reward.fixedDiscountAmount)"
        />
      </v-col>
    </v-row>
    <v-row justify="start" class="mt-2">
      <v-col cols="12">
        <h3>Points Required To Redeem</h3>
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col cols="6">
        <v-text-field
          v-model.number="reward.pointsCost"
          label="Points Cost"
          outlined
          :error-messages="getFieldError($v.reward.pointsCost)"
        />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"
import getFieldError from "@/mixins/getFieldError"

export default {
  name: "add-edit-form",
  components: {
    FormDialog,
  },
  mixins: [getFieldError],
  data() {
    return {
      reward: {
        pointsCost: null,
        type: "",
        isEnabled: true,
      },
      rewardTypes: [
        {
          text: "Free Item",
          value: "freeItem",
        },
        {
          text: "Fixed Discount",
          value: "fixedDiscount",
        },
      ],
      mainErrorMessage: "",
      closeDisabled: false,
      awaitingResponse: false,
    }
  },
  validations() {
    return {
      reward: {
        pointsCost: { required, numeric },
        type: { required },
      },
    }
  },
  computed: {
    ...mapState(["loyaltyRewards", "menuItems"]),
    editMode() {
      return this.$route.params.id != null
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
    "reward.type": {
      handler(newValue) {
        if (!newValue) return
        if (newValue === "freeItem") {
          this.reward?.fixedDiscountAmount
            ? delete this.reward.fixedDiscountAmount
            : null
          return
        }
        this.reward.menuItemId ? delete this.reward.menuItemId : null
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async submitHandle() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.closeDisabled = this.awaitingResponse = true

      await this.$store
        .dispatch("updateLoyaltyReward", this.reward)
        .then(() => {
          this.clearFields()
        })
        .catch(err => {
          this.mainErrorMessage = err
        })
      this.awaitingResponse = this.closeDisabled = false
    },
    clearFields() {
      this.reward = {
        pointsCost: null,
        type: "",
        isEnabled: true,
      }
      this.$v.$reset()
      this.mainErrorMessage = ""
      this.$router.push({ params: { form: null, id: null } })
    },
    prepEdit() {
      this.reward = this.loyaltyRewards.find(
        reward => reward.id == this.$route.params.id
      )
    },
  },
}
</script>
