<template>
  <div v-if="$route.params.tab == 'thirdPartyTypes'">
    <page-component
      pageTitle="Third Party Types"
      :headers="headers"
      :items="thirdPartyTypes"
      :buttons="[
        {
          text: '+ Add New Third Party Type',
          to: { params: { form: 'AddEditForm' } },
        },
      ]"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="edit" @click="editThirdPartyType(item)" />
        <nxg-action-btn type="delete" item-type="THIRD_PARTY_TYPE" :item="item" />
      </template>
    </page-component>
    <add-edit-form :thirdPartyTypes="thirdPartyTypes" />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import PageComponent from "../../shared/PageComponent.vue"
import { mapState } from "vuex"

export default {
  name: "third-party-types",
  components: {
    AddEditForm,
    PageComponent,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", class: "header-style" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState(["thirdPartyTypes"]),
  },
  methods: {
    async editThirdPartyType(thirdParty) {
      this.$router.push({ params: { form: "AddEditForm", id: thirdParty.id } })
    },
  },
}
</script>
